<template>
  <div class="registration" style="margin-bottom: 2em;">
    <p>BeamNG.tech software is a great solution for everyone who uses simulation in in their research and development across a wide range of industries, especially the automotive sector. With BeamNG.tech you have direct access to a wide range of features which are exclusively available in this package.</p>
    <p>BeamNG.tech software is subject to annual maintenance and support fees when used for commercial purposes. For more information, please <a href="mailto:licensing@beamng.gmbh">contact us.</a></p>
    <p>Academic licenses provide the right to use the software for up to one year with the possibility of renewal. To ensure uninterrupted service, you may renew your license prior to the conclusion of the license term. Non-commercial licenses (is not for commercial staff and for profit or revenue-generating activities) are provided at our discretion for agreed period of time with the possibility of renewal. The application process requires filling out your name, email address, organization name, and written statement regarding how you intend to use BeamNG.tech. <b>Please use your professional/academic email address as proof of membership and include a detailed description of your use case.</b> Upon submitting the application request you will receive an email notification stating that we have received it, as well as an email containing our response within 5 business days. If approved, the email will include download and installation instructions.</p>
    <form @submit="submit" v-if="result == null">
      <hr />
      <div class="alert alert-dismissable fade show alert-danger" v-if="nameError">
        Please enter your full name.
        <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="alert alert-dismissable fade show alert-danger" v-if="emailError">
        Please enter a valid email address.
        <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="applicationName">Name:</label>
            <input type="text" class="form-control" id="applicationName" aria-describedby="nameHelp" placeholder="Enter your full name" v-model.trim="name" />
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label for="applicationEmail">Email Address:</label>
            <input type="email" class="form-control" id="applicationEmail" aria-describedby="emailHelp" placeholder="Enter the email" v-model.trim="email" maxlength="50" />
          </div>
        </div>
      </div>
      <div class="alert alert-dismissable fade show alert-danger" v-if="organizationError">
        Please enter an organization name.
        <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="form-group">
        <label for="applicationOrganization">Organization name:</label>
        <input type="text" class="form-control" id="applicationOrganization" aria-describedby="organizationHelp" placeholder="Enter the organization name" v-model.trim="organization" />
      </div>
      <div class="alert alert-dismissable fade show alert-danger" v-if="applicationError">
        Please provide comprehensive details regarding your planned use of BeamNG.tech (more than 100 characters).
        <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="form-group">
        <label for="applicationText">Application Text (min. 100 characters):</label>
        <textarea class="form-control" id="applicationText" aria-describedby="applicationHelp" minlength="100" placeholder="Please provide comprehensive details regarding your planned use of BeamNG.tech. Thorough information will enable a quicker and more efficient application review process." v-model.trim="text"></textarea>
      </div>
      <p>Please tick the boxes below to confirm that you agree to comply with the <a target="_blank" href="https://beamng.tech/policies/tos/">Terms of Service</a> and then <a target="_blank" href="https://beamng.tech/policies/privacy-policy/">Privacy Policy</a>.</p>
      <div class="alert alert-dismissable fade show alert-danger" v-if="tosError">
        You need to agree to the <a target="_blank" href="https://beamng.tech/policies/tos/">Terms of Service</a> to apply.
        <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="form-check">
        <input type="checkbox" class="form-check-input" id="tos" v-model="tos" />
        <label class="form-check-label" for="tos">I have read and agree to the <a target="_blank" href="https://beamng.tech/policies/tos/">Terms of Service.</a></label>
      </div>
      <div class="alert alert-dismissable fade show alert-danger" v-if="privacyError">
        You need to agree to the <a target="_blank" href="https://beamng.tech/policies/privacy-policy/">Privacy Policy</a> to apply.
        <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="form-check">
        <input type="checkbox" class="form-check-input" id="privacy" v-model="privacy" />
        <label class="form-check-label" for="privacy">I have read and agree to the <a target="_blank" href="https://beamng.tech/policies/privacy-policy/">Privacy Policy.</a></label>
      </div>
      <div class="form-check">
        <input type="checkbox" class="form-check-input" id="newsletter" v-model="newsletter" />
        <label class="form-check-label" for="newsletter">I want to receive updates about BeamNG.tech. <i>Optional field.</i></label>
      </div>
      <div class="alert alert-dismissable fade show alert-danger" v-if="tokenError">
        Please complete the verification.
        <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div>
        <vue-turnstile site-key="0x4AAAAAABCjqrqbVOIu_Cc4" theme="light" @verified="token = $event" />
      </div>
      <hr />
      <button type="submit" class="btn btn-primary">Submit</button>
    </form>
    <div class="alert alert-dismissable fade show alert-primary" v-if="result == 'pending'">
      Your application has been received and is pending approval. You will be emailed about the result. If you are not notified, remember to check your spam folder.
    </div>
    <div class="alert alert-dismissable fade show alert-warn" v-if="result == 'existing'">
      An application using your email already exists. If you think this is an error or simply haven't gotten a response to your existing application yet, <a href="mailto:licensing@beamng.gmbh">contact us.</a>
    </div>
  </div>
</template>

<script>
import VueTurnstile from '@gaviti/vue-turnstile';

export default {
  name: 'RegistrationForm',
  components: { VueTurnstile },
  data: function() {
    return {
      name: '',
      email: '',
      text: '',
      organization: '',
      tos: false,
      privacy: false,
      newsletter: false,

      emailError: false,
      nameError: false,
      applicationError: false,
      tosError: false,
      privacyError: false,
      organizationError: false,
      tokenError: false,
      token: null,
      result: null,
    }
  },
  methods: {
    submit: async function(ev) {
      ev.preventDefault();

      this.tosError = !this.tos;
      this.privacyError = !this.privacy;
      this.nameError = this.name.length === 0;
      this.emailError = this.email.length === 0;
      this.organizationError = this.organization.length === 0;
      this.applicationError = this.text.length < 100;
      this.tokenError = this.token === null || this.token.length === 0;

      if (this.nameError || this.emailError || this.organizationError || this.tosError || this.applicationError || this.privacyError || this.tokenError) {
        return;
      }

      var data = {
        tos: this.tos,
        privacy: this.privacy,
        newsletter: this.newsletter,
        name: this.name,
        email: this.email,
        organization: this.organization,
        text: this.text,
        turnstile: this.token,
      };

      this.axios.put('/s6/v1/researchLicenses', data).then((response) => {
        this.result = response.data.result;
      });
    }
  }
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a, a:hover {
  color: #FF8800;
}

div.registration p {
  text-align: justify;
}

.alert {
  margin-bottom: 0;
}
</style>
